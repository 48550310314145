<template>
  <div v-if="form" class="editor">
      
    <TopHeader nav="Editor" :data="form">
        <Btn @click.native="showPreview(form)" type="secondary" size="sm"><Icon name="eye" /> Ver</Btn>
        <Btn @click.native="openShareLink()" type="outline" size="sm"><Icon name="share-square" type="solid" /></Btn>
    </TopHeader>

    <PublishLinkPopover ref="shareLink" :active="showShareLink" :slug="form.slug" v-on:publish-link-close="closeShareLink"/>


      <main class="page-content"> 

        <!-- Page Header -->
        <div class="container container-md main-content mt64">
            <div class="page-header mb32">
                <h1 class="page-title title-xl mb8">Exportações recentes</h1>
                <p class="page-description title-sm light">
                  {{form.name}}
                 </p>
            </div>

            <!-- Files table -->
            <section class="filesList">                
                <TableView v-if="files" :headers="['Arquivo', 'Tamanho', 'Data', 'Download']" class="export-table" >
                    <tr v-for="file in files" :key="file.path" >
                        <td class="col col-name"><Icon name="file-alt" class="mr8" /> {{limit(file.filename, 60)}}</td>
                        <td class="col">{{formatBytes(file.size, 0)}}</td>
                        <td class="col">{{showTimeAgo(file.created_at)}}</td>
                        <td class="col">
                            <TagLabel v-if="file.status != 'complete'" type="warning" v-tooltip="'Exportando seu formulário, aguarde.'">Exportando</TagLabel>
                            <Btn v-else :href="$api + 'file/download?asset=' + file.path" target="_self" size="xs" type="outline">Baixar arquivo</Btn>
                        </td>
                    </tr>
                </TableView>
                <p class="legend mt16">Apenas as últimas 5 exportações são exibidas.</p>
            </section>
        </div> 
    </main>

    <footer class="footer"></footer>

  </div>
</template>

<script>
import PublishLinkPopover from '../components/PublishLinkPopover.vue'
import TagLabel from '../components/TagLabel.vue'
import TableView from '../components/TableView.vue'
import Btn from '@/components/form/Btn.vue';
import Icon from '@/components/Icon.vue';
import TopHeader from '@/components/TopHeader.vue';
import SectionHeader from '@/components/SectionHeader.vue';
import { FormMixin } from '@/mixins/FormMixin';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';

export default {
  name: 'ExportAnswers',
  mixins: [FormMixin, UtilitiesMixin],
  components: {
    TopHeader,
    Btn,
    SectionHeader,
    Icon, TableView, TagLabel, PublishLinkPopover
  },

  mounted () {
      this.getFiles()
      this.poll = setInterval(() => {
          if(document.hasFocus()) this.getFiles()
		}, 10000)
  },

    beforeDestroy () {
        clearInterval(this.poll)
    },

    data() {
        return {
            files: null,
            poll: null,
            pollCounter: 0,
            showShareLink: false
        }
    },

    watch: {
        pollCounter(value) {
            // :: Evita do counter ficar pra sempre
            if(value > 180) clearInterval(this.poll)          
        }
    },


  methods: {

    getFiles() {
        this.$axios.get(process.env.VUE_APP_API_URL + 'answers/export/' + this.slug)
        .then(response => {
            this.files = response.data.data
            this.pollCounter++
        })
    },

    openShareLink(){this.showShareLink = true},
    closeShareLink(){this.showShareLink = false},
  },
  
}

</script>

<style lang="sass">

.export-table
    .table-head
        .col
            text-align: right

            &:first-of-type
                text-align: left

    .table-body    
        .col
            text-align: right

        .col.col-name
            padding-left: 0
            text-align: left

.legend
    font-size: $fzXs
    color: $cG5


</style>
